select {
    height: 30px;
    width: 100px;
}

select:focus,
textarea {
    font-size: 16px;
}

.react-datepicker__navigation-icon {
    top: 5px;
}

.react-datepicker__year-wrapper {
    justify-content: center;
}

.datepicker{
    width: fit-content;
}