
html,body {
    /* background-image: url("./assets/icons/BG-PMI-survey.png"); */
    background: linear-gradient(#1998dd, #031b7b);
    background-position: center;
    background-size: 440px 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    margin: auto;
    padding: 0;
    overflow-x: hidden;
    width: 440px;
}

@media (max-width: 500px) {
    html,body {
        width: 100%;
        margin: 0 auto;
    }
}

